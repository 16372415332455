<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-3">
        <div class="card" style="padding: 5px 0">
          <div class="card-body">
            <div class="media flex align-items-center">
              <div class="me-3">
                <i class="mdi mdi-account-circle text-primary h1"></i>
              </div>

              <div class="media-body">
                <div class="text-muted">
                  <h5>{{ userData.name }}</h5>
                  <p class="mb-1">{{ userData.email }}</p>
                  <p class="mb-0">Id no: #{{ userData.id }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="card-body border-top">
            <div class="row">
              <div class="col-sm-6">
                <div>
                  <p class="text-muted mb-2">Available Balance</p>
                  <h5>$ 9148.23</h5>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="text-sm-end mt-4 mt-sm-0">
                  <p class="text-muted mb-2">Since last month</p>
                  <h5>
                    + $ 248.35
                    <span class="badge bg-success ms-1 align-bottom"
                      >+ 1.3 %</span
                    >
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body border-top">
            <p class="text-muted mb-4">In this month</p>
            <div class="text-center">
              <div class="row">
                <div class="col-sm-4">
                  <div>
                    <div class="font-size-24 text-primary mb-2">
                      <i class="bx bx-send"></i>
                    </div>

                    <p class="text-muted mb-2">Send</p>
                    <h5>$ 654.42</h5>

                    <div class="mt-3">
                      <a href="#" class="btn btn-primary btn-sm w-md">Send</a>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="mt-4 mt-sm-0">
                    <div class="font-size-24 text-primary mb-2">
                      <i class="bx bx-import"></i>
                    </div>

                    <p class="text-muted mb-2">receive</p>
                    <h5>$ 1054.32</h5>

                    <div class="mt-3">
                      <a href="#" class="btn btn-primary btn-sm w-md"
                        >Receive</a
                      >
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="mt-4 mt-sm-0">
                    <div class="font-size-24 text-primary mb-2">
                      <i class="bx bx-wallet"></i>
                    </div>

                    <p class="text-muted mb-2">Withdraw</p>
                    <h5>$ 824.34</h5>

                    <div class="mt-3">
                      <a href="#" class="btn btn-primary btn-sm w-md"
                        >Withdraw</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <div class="col-xl-9">
        <div class="row">
          <div
            class="col-sm-4"
            v-for="(wallet, index) in walletInfo"
            :key="index"
          >
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="media">
                  <div
                    v-if="wallet.type == 'ETH'"
                    class="me-3 align-self-center"
                  >
                    <i class="mdi mdi-ethereum h2 text-primary mb-0"></i>
                  </div>

                  <div
                    v-if="wallet.type == 'BTC'"
                    class="me-3 align-self-center"
                  >
                    <i class="mdi mdi-bitcoin h2 text-warning mb-0"></i>
                  </div>

                  <div
                    v-if="wallet.type == 'TRON'"
                    class="me-3 align-self-center"
                  >
                    <span v-html="tron"></span>
                  </div>
                  <div class="media-body">
                    <p class="text-muted mb-2">
                      {{
                        wallet.type == "BTC"
                          ? "Bitcoin"
                          : wallet.type == "ETH"
                          ? "Ethereum"
                          : wallet.type == "TRON"
                          ? "Tron"
                          : ""
                      }}
                      Wallet
                    </p>
                    <h5 class="mb-0">
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          class="form-control"
                          :value="wallet.address"
                          aria-label=""
                          aria-describedby="basic-addon2"
                          :id="`basic-addon-${index}`"
                        />
                        <span
                          class="
                            input-group-text
                            bg-primary
                            text-white
                            border-primary
                            px-4
                          "
                          :id="`textShow${index}`"
                          style="cursor: pointer"
                          @click="
                            copyAddress(
                              'basic-addon-' + index,
                              index,
                              'textShow' + index
                            )
                          "
                          >{{ copy_text[index] }}</span
                        >
                      </div>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="col-sm-4">
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="media">
                  <div class="me-3 align-self-center">
                    <i class="mdi mdi-bitcoin h2 text-warning mb-0"></i>
                  </div>
                  <div class="media-body">
                    <p class="text-muted mb-2">Bitcoin Wallet</p>
                    <h5 class="mb-0">
                   

                      <div class="input-group mb-3" v-if="walletInfo[1]">
                        <input
                          type="text"
                          class="form-control"
                          :value="walletInfo[1].address"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          id="basic-addon0"
                        />
                        <span
                          class="
                            input-group-text
                            bg-primary
                            text-white
                            border-primary
                            px-4
                          "
                          style="cursor: pointer"
                          @click="copyAddress('basic-addon0', 1)"
                          >{{ copy_text[1] }}</span
                        >
                      </div>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-4">
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="media">
                  <div class="me-3 align-self-center">
                    <span v-html="tron"></span>
                  </div>
                  <div class="media-body">
                    <p class="text-muted mb-2">Tron Wallet</p>
                    <h5 class="mb-0">
                      <div class="input-group mb-3" v-if="walletInfo[2]">
                        <input
                          type="text"
                          class="form-control"
                          :value="walletInfo[2].address"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          id="basic-addon2"
                        />
                        <span
                          class="
                            input-group-text
                            bg-primary
                            text-white
                            border-primary
                            px-4
                          "
                          style="cursor: pointer"
                          @click="copyAddress('basic-addon2', 2)"
                          >{{ copy_text[2] }}</span
                        >
                      </div>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Activities</h4>
            <b-tabs content-class="mt-3" nav-class="nav-tabs-custom">
              <b-tab title="All" active>
                <!-- <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center fw-normal">
                        Show&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                          class="form-control form-select form-select-sm"
                        ></b-form-select
                        >&nbsp;entries
                      </label>
                    </div>
                  </div>
              
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-end"
                    >
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ms-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                 
                </div> -->
                <!-- Table -->
                <div class="table-responsive table-hover mb-0">
                  <b-table
                    :items="activitiesData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                    <template v-slot:cell(created_at)="data">
                      <p>
                        {{ new Date(data.item.created_at).toLocaleString() }}
                      </p>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-end
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Buy">
                <!-- Table -->
                <div class="table-responsive table-hover mb-0">
                  <b-table
                    :items="buyData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                    <template v-slot:cell(created_at)="data">
                      <p>
                        {{ new Date(data.item.created_at).toLocaleString() }}
                      </p>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-end
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rowsBuy"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Sell">
                <!-- Table -->
                <div class="table-responsive table-hover mb-0">
                  <b-table
                    :items="sellData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                    <template v-slot:cell(created_at)="data">
                      <p>
                        {{ new Date(data.item.created_at).toLocaleString() }}
                      </p>
                    </template></b-table
                  >
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-end
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rowsSell"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import ApiClass from "../../../api/api";

// import { OveviewChart } from "./data";
/**
 * Wallets component
 */
export default {
  page: {
    title: "Wallets",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      tron: '<svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 64 64"><defs><style>.cls-1{fill:#ff060a;}</style></defs><title>tron</title><g id="tron"><path class="cls-1" d="M61.55,19.28c-3-2.77-7.15-7-10.53-10l-.2-.14a3.82,3.82,0,0,0-1.11-.62l0,0C41.56,7,3.63-.09,2.89,0a1.4,1.4,0,0,0-.58.22L2.12.37a2.23,2.23,0,0,0-.52.84l-.05.13v.71l0,.11C5.82,14.05,22.68,53,26,62.14c.2.62.58,1.8,1.29,1.86h.16c.38,0,2-2.14,2-2.14S58.41,26.74,61.34,23a9.46,9.46,0,0,0,1-1.48A2.41,2.41,0,0,0,61.55,19.28ZM36.88,23.37,49.24,13.12l7.25,6.68Zm-4.8-.67L10.8,5.26l34.43,6.35ZM34,27.27l21.78-3.51-24.9,30ZM7.91,7,30.3,26,27.06,53.78Z"/></g></svg>',
      // OveviewChart: OveviewChart,
      title: "Wallets",
      items: [
        {
          text: "Crypto",
          href: "#",
        },
        {
          text: "Wallet",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        {
          key: "id",
          label: "ID No",
          sortable: true,
        },

        {
          key: "order_type",
          sortable: true,
        },
        {
          key: "currency",
          sortable: true,
        },
        {
          key: "quantity",
          sortable: true,
        },
        {
          label: "Status",
          key: "current_status",
          sortable: true,
        },

        {
          key: "created_at",
          sortable: true,
        },
      ],
      userData: "",
      walletInfo: [],
      buyData: [],
      sellData: [],
      activitiesData: [],
      copy_text: ["Copy", "Copy", "Copy"],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.activitiesData.length;
    },
    rowsBuy() {
      return this.buyData.length;
    },
    rowsSell() {
      return this.sellData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.adminGet();

    // console.log(this.buyData);
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    // copyAddress(id = null, index = null) {
    //   var copyText = document.getElementById(id);
    //   copyText.select();
    //   copyText.setSelectionRange(0, 99999);
    //   var res = document.execCommand("copy");
    //   console.log(res);
    //   var $this = this;
    //   $this.copy_text[index] = "Copied";

    //   setTimeout(() => {
    //     $this.copy_text[index] = "Copy";
    //   }, 2000);
    // },

    copyAddress(id = null, index = null, texid = null) {
      var copyText = document.getElementById(id);
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      var res = document.execCommand("copy");

      this.copy_text[index] = res
        ? (document.getElementById(texid).innerHTML = "Copied")
        : "Copy";

      setTimeout(() => {
        document.getElementById(texid).innerHTML = "Copy";
        copyText.blur();
      }, 2000);
    },

    async adminGet() {
      var res = await ApiClass.getRequest("admin/users/admin_info_get", true);
      // console.log("admin=======", res);
      this.userData = res.data.data.user;
      this.walletInfo = res.data.data.wallet;
      // console.log("wallet info=", this.walletInfo);
      this.activitiesData = res.data.data.orders;
      this.buyData = this.activitiesData.filter(
        (item) => item.order_type == "buy"
      );
      this.sellData = this.activitiesData.filter(
        (item) => item.order_type == "sell"
      );
      //http://192.168.10.48/Tacc/exchange/public/api/admin/users/admin_info_get
    },
  },
};
</script>

<style scoped>
.cus-width {
  max-width: 280px;
}
@media all and (max-width: 1900px) {
  .cus-width {
    max-width: 220px;
  }
}
@media all and (max-width: 1600px) {
  .cus-width {
    max-width: 180px;
  }
}
@media all and (max-width: 1400px) {
  .cus-width {
    max-width: 160px;
  }
}
@media all and (max-width: 1200px) {
  .cus-width {
    max-width: 110px;
  }
}
@media all and (max-width: 1100px) {
  .cus-width {
    max-width: 170px;
  }
}

@media all and (max-width: 600px) {
  .cus-width {
    max-width: 100px;
  }
}
@media all and (max-width: 500px) {
  .cus-width {
    max-width: 200px;
  }
}
</style>
